import { useState } from 'react';
import { Link } from 'react-router-dom';
import { photosDB } from '../../../../database/photosDB';
import { Card } from '../card/card';
//import { Gallery } from '../gallery/gallery';
import Modal from '../modal/modal';

import './cardList.scss';

interface ICardList {
   pictures: string[];
}

export const CardList: React.FC<ICardList> = (props) => {
   const { pictures } = props;

   const [clickedImg, setClickedImg] = useState('');
   const [currentIndex, setCurrentIndex] = useState(0);

   const handleClick = (item: string, index: number) => {
      setCurrentIndex(index);
      setClickedImg(item);
   };

   const handleRotationRight = () => {
      const totalLength: number = pictures.length;

      if (currentIndex === totalLength - 1) {
         setCurrentIndex(0);
         const newImage = pictures[0];
         setClickedImg(newImage);
      } else {
         const newIndex = currentIndex + 1;
         const newImage = pictures[newIndex];
         setClickedImg(newImage);
         setCurrentIndex(newIndex);
      }
   };

   const handleRotationLeft = () => {
      const totalLength: number = pictures.length;

      if (currentIndex === 0) {
         setCurrentIndex(totalLength - 1);
         const newImage = pictures[totalLength - 1];
         setClickedImg(newImage);
      } else {
         const newIndex = currentIndex - 1;
         const newImage = pictures[newIndex];
         setClickedImg(newImage);
         setCurrentIndex(newIndex);
      }
   };

   return (
      <div className="card-list">
         {/* <div className="prev-page-title">
            <Link to={'/portfolio'} className="prev-page">
               <FontAwesomeIcon icon={faArrowLeftLong} />
            </Link>
            <h2>{title}</h2>
         </div> */}
         <div className="card-list__items">
            {pictures.map((picture: string, index: number) => (
               <div
                  className="img-wrapper"
                  key={picture}
                  onClick={() => handleClick(picture, index)}
               >
                  <Card source={picture} />
               </div>
            ))}
         </div>
         {clickedImg && (
            <Modal
               clickedImg={clickedImg}
               handleRotationRight={handleRotationRight}
               setClickedImg={setClickedImg}
               handleRotationLeft={handleRotationLeft}
            />
         )}
      </div>
   );
};
