import './card.scss';

interface ICard {
   source: string;
}

export const Card: React.FC<ICard> = (props) => {
   const { source } = props;

   return (
      <div className="img-wrapper">
         <img src={source} alt="" />
      </div>
   );
};
