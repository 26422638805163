import { Link, useParams } from 'react-router-dom';
import { photosDB } from '../../../database/photosDB';
import { CardList } from '../components/cardList/cardList';
import ErrorPage from '../../error/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faArrowLeftLong,
   faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import './detailsPage.scss';

const arrowLinkToPortfolio = (
   <Link to={'/portfolio'} className="prev-page">
      <FontAwesomeIcon icon={faArrowLeftLong} />
   </Link>
);

const linkToPortfolio = (
   <Link to={'/portfolio'} className="link-to-portfolio">
      Retour au portfolio
   </Link>
);

const pageTitles: { [name: string]: string } = {
   illustrations: 'Illustrations & Motifs',
   'faire-parts': 'Faire-parts',
   'designs-graphiques': 'Designs graphiques',
};

const pageContents: { [name: string]: string[] } = {
   illustrations: ['Illustrations', 'Motifs'],
   'faire-parts': ['Faire-parts'],
   'designs-graphiques': ['Logos', 'Cartes de visite'],
};

const pagePictures: { [name: string]: string } = {
   Illustrations: 'illustrations',
   Motifs: 'patterns',
   'Faire-parts': 'announcements',
   Logos: 'logos',
   'Cartes de visite': 'businessCards',
};

const DetailsPage: React.FC = () => {
   const { genre } = useParams();
   if (pageTitles[genre ?? ''] === undefined) {
      return <ErrorPage />;
   }
   return (
      <div className="details">
         <div className="prev-page-title">
            {arrowLinkToPortfolio}
            <h2>{pageTitles[genre ?? '']}</h2>
         </div>
         {pageContents[genre ?? ''].map((value, index) => (
            <div key={index}>
               <h3>{value}</h3>
               <CardList
                  pictures={photosDB.get(pagePictures[value]) as string[]}
               />
            </div>
         ))}
         {linkToPortfolio}
      </div>
   );
};

export default DetailsPage;
