import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/Hello.ttf';
import './index.scss';

import reportWebVitals from './reportWebVitals';
import { AppRouter } from './routing/router';

ReactDOM.render(
   <React.StrictMode>
      <AppRouter />
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
