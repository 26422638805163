import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../assets/images/logo-latelier-de-myia.png';
import './header.scss';

export const Header: React.FC = () => {
   const { pathname } = useLocation();
   const [isNavBarOpen, setNavbarOpen] = useState(false);
   const [isBigScreen, setSmallScreen] = useState(
      window.matchMedia('(min-width: 768px)').matches
   );

   const navUrl = ['/', '/portfolio', '/about', '/contact'];

   const navItems = ['Home', 'Portfolio', 'A propos', 'Contact'];

   const navLinks = navItems.map((el, index) => {
      return (
         <Link
            to={navUrl[index]}
            className="nav__item nav-link"
            style={{
               color: isBigScreen
                  ? pathname === '/'
                     ? '#426361'
                     : '#6d9e9b'
                  : '#ffffff',
            }}
         >
            {el}
         </Link>
      );
   });

   const closeMobileNav = () => {
      setNavbarOpen(false);
   };

   const smallScreen = () => {
      return (
         <div className="header-mobile">
            <Link to="/" className="header-mobile__logo">
               <img
                  src={logo}
                  alt="retourner à l'accueil"
                  className="header-mobile__logo"
               />
            </Link>

            <div className="nav-mobile">
               <button
                  onClick={() => setNavbarOpen(true)}
                  className="toggle-btn"
               >
                  <FontAwesomeIcon icon={faBars} />
               </button>

               {isNavBarOpen && (
                  <nav className="menu-displayed">
                     <button onClick={closeMobileNav} className="close-btn">
                        <FontAwesomeIcon icon={faX} />
                     </button>

                     <ul onClick={closeMobileNav}>
                        {navLinks.map((el) => {
                           return <li>{el}</li>;
                        })}
                     </ul>
                  </nav>
               )}
            </div>
         </div>
      );
   };

   const bigScreen = () => {
      return (
         <div
            className="header"
            style={{
               background:
                  pathname === '/'
                     ? 'white'
                     : `linear-gradient(
               to left,
               rgba(173, 218, 213, 0.6),
               rgba(255, 255, 255, 0.9)
            )`,
            }}
         >
            <Link to="/" className="homepage-link">
               <img
                  src={logo}
                  alt="retourner à l'accueil"
                  className="header__logo"
               />
            </Link>
            <nav
               style={{
                  color: pathname === '/' ? '#6d9e9b' : '#426361',
               }}
            >
               {navLinks}
            </nav>
         </div>
      );
   };

   useEffect(() => {
      window
         .matchMedia('(min-width: 768px)')
         .addEventListener('change', (e) => setSmallScreen(e.matches));
   });

   return isBigScreen ? bigScreen() : smallScreen();
};
