import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faFacebook,
   faInstagram,
   faLinkedin,
   faPinterest,
} from '@fortawesome/free-brands-svg-icons';
import watercolorBackground from '../../../assets/images/fond-aquarelle.png';
import 'font-awesome/css/font-awesome.min.css';

import './footer.scss';
import { Link } from 'react-router-dom';

const links = [
   'https://m.facebook.com/atelierdemyia',
   'https://www.instagram.com/atelier_de_myia/',
   'https://www.linkedin.com/in/maryiam-tarawally-4b581215b/',
   'https://pl.pinterest.com/maryiamtc/?amp_client_id=CLIENT_ID(_)&mweb_unauth_id=%7B%7Bdefault.session%7D%7D',
];
const logos = [faFacebook, faInstagram, faLinkedin, faPinterest];

export const Footer: React.FC = () => (
   <div className="footer">
      <div className="social-media">
         {links.map((link, index) => (
            <div key={index}>
               <a href={link} rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon
                     icon={logos[index]}
                     className="social-media__icon"
                  ></FontAwesomeIcon>
               </a>
            </div>
         ))}
      </div>
      <img
         src={watercolorBackground}
         alt=""
         className="watercolor-background"
      />

      <p className="footer__text">
         © Copyright 2022 Maryiam Tarawally, L'Atelier de Myia | Contact |
         Mention légales
      </p>
   </div>
);
