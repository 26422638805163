import bestFriends from '../assets/images/illustrations/best-friends.jpg';
import birdCloud from '../assets/images/illustrations/bird-in-the-clouds.jpg';
import boat from '../assets/images/illustrations/boat-new-dimension.jpg';
import easterBunny from '../assets/images/illustrations/easter-bunny-new-dimension.jpg';
import fairyCat from '../assets/images/illustrations/fairy-cat.jpg';
import girlSunset from '../assets/images/illustrations/girl-sunset.jpg';
import bee from '../assets/images/illustrations/little-bee.jpg';
import littleBoat from '../assets/images/illustrations/little-boat.jpg';
import chick from '../assets/images/illustrations/petit-poussin-easter.jpg';
import racoon from '../assets/images/illustrations/racoon.jpg';
import patternFox from '../assets/images/illustrations/pattern-fox.jpg';
import patternKoala from '../assets/images/illustrations/pattern-koala.jpg';
import patternLion from '../assets/images/illustrations/pattern-lion.jpg';
import patternRacoon from '../assets/images/illustrations/pattern-racoon.jpg';

import fairePart from '../assets/images/mariage-faire-part.jpg';
import menu from '../assets/images/mariage-menu.jpg';
import setup from '../assets/images/mariage-setup.jpg';
import bapteme from '../assets/images/bapteme-raph.jpg';

import manoushkamouthLogo from '../assets/images/logo-manoushkamouth.png';
import manoushkamouthMS from '../assets/images/logo-ms-manoushkamouth.jpg';
import savonnerieLogo from '../assets/images/logo-savonnerie-de-la-coquille.jpg';
import savonnerieMS from '../assets/images/logo-ms-savonnerie-de-la-coquille.jpg';

import carteVisiteAtelierMyia from '../assets/images/carte-atelier-myia.jpg';
import carteVisiteLudo from '../assets/images/carte-de-visite-ludo.jpg';

export const photosDB = new Map<string, string[]>([
   [
      'illustrations',
      [
         bestFriends,
         birdCloud,
         boat,
         easterBunny,
         fairyCat,
         girlSunset,
         bee,
         littleBoat,
         chick,
         racoon,
      ],
   ],
   ['patterns', [patternFox, patternKoala, patternLion, patternRacoon]],
   ['announcements', [fairePart, menu, setup, bapteme]],
   [
      'logos',
      [manoushkamouthLogo, manoushkamouthMS, savonnerieLogo, savonnerieMS],
   ],
   ['businessCards', [carteVisiteAtelierMyia, carteVisiteLudo]],
]);
