import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faChevronLeft,
   faChevronRight,
   faX,
} from '@fortawesome/free-solid-svg-icons';
import './modal.scss';
import path from 'path';

interface IModal {
   clickedImg: string;
   handleRotationRight: () => void;
   setClickedImg: React.Dispatch<React.SetStateAction<string>>;
   handleRotationLeft: () => void;
}

const Modal: React.FC<IModal> = ({
   clickedImg,
   handleRotationRight,
   setClickedImg,
   handleRotationLeft,
}) => {
   const closeModal = (e: React.SyntheticEvent, closeNeeded: boolean) => {
      e.stopPropagation();
      if (closeNeeded) {
         setClickedImg('');
      }
   };

   return (
      //<>
      <div className="overlay dismiss" onClick={(e) => closeModal(e, (e.target as Element).classList.contains('dismiss'))}>
         <FontAwesomeIcon
            icon={faX}
            className = "close-btn"
            onClick={(e) => closeModal(e, true)}
         />

         {/* <div className="modal-wrapper"> */}

         <FontAwesomeIcon
            className="navigation-btn chevron-left"
            onClick={handleRotationLeft}
            icon={faChevronLeft}
         />

         <img className="overlay__img" src={clickedImg} alt="" />

         <FontAwesomeIcon
            className="navigation-btn chevron-right"
            onClick={handleRotationRight}
            icon={faChevronRight}
         />

         {/* </div> */}
      </div>
      //</>
   );
};

export default Modal;
