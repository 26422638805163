import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DetailsPage from '../presentation/portfolio/detailsPage/detailsPage';
import { Footer } from '../presentation/sharedComponents/footer/footer';
import { Header } from '../presentation/sharedComponents/header/header';

const HomePage = React.lazy(() => import('../presentation/homePage/homePage'));
const AboutPage = React.lazy(() => import('../presentation/about/about'));
const PortfolioPage = React.lazy(
   () => import('../presentation/portfolio/portfolio')
);
const ContactPage = React.lazy(
   () => import('../presentation/contact/contactPage')
);
const ErrorPage = React.lazy(() => import('../presentation/error/error'));

export const AppRouter: React.FC = () => {
   return (
      <BrowserRouter>
         <Suspense fallback={<div>Loading...</div>}>
            <Header />
            <Routes>
               <Route path="/" element={<HomePage />} />
               <Route path="/portfolio" element={<PortfolioPage />} />
               <Route path="/portfolio/:genre" element={<DetailsPage />} />
               <Route path="/about" element={<AboutPage />} />
               <Route path="/contact" element={<ContactPage />} />
               <Route path="/*" element={<ErrorPage />} />
            </Routes>
            <Footer />
         </Suspense>
      </BrowserRouter>
   );
};
